import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AutosavedStatusComponent } from '@ion-components/autosaved-status/autosaved-status.component';
import { CalendarIconComponent } from '@ion-components/calendar-icon/calendar-icon.component';
import { ChangeChipComponent } from '@ion-components/change-chip/change-chip.component';
import { ClockIconComponent } from '@ion-components/clock-icon/clock-icon.component';
import { ColorPickerComponent } from '@ion-components/color-picker/color-picker.component';
import { ComparisonComponent } from '@ion-components/comparison/comparison.component';
import { InputErrorComponent } from '@ion-components/input-error/input-error.component';
import { InputHelpComponent } from '@ion-components/input-help/input-help.component';
import { KeyMetricsComponent } from '@ion-components/key-metrics/key-metrics.component';
import { LiveIconComponent } from '@ion-components/live-icon/live-icon.component';
import { OnlineStatusComponent } from '@ion-components/online-status/online-status.component';
import { PasscodeInputComponent } from '@ion-components/passcode-input/passcode-input.component';
import { Panel2Component } from '@ion-components/panel-2/panel-2.component';
import { Panel3Component } from '@ion-components/panel-3/panel-3.component';
import { PopoverListComponent } from '@ion-components/popover-list/popover-list.component';
import { SignoutButtonComponent } from '@ion-components/signout-button/signout-button.component';
import { SpinnerButtonComponent } from '@ion-components/spinner-button/spinner-button.component';
import { ToolbarComponent } from '@ion-components/toolbar/toolbar.component';
import { ToolbarPopoverComponent } from '@ion-components/toolbar-popover/toolbar-popover.component';

import { PipesModule } from '@pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  declarations: [
    AutosavedStatusComponent,
    CalendarIconComponent,
    ChangeChipComponent,
    ClockIconComponent,
    ColorPickerComponent,
    ComparisonComponent,
    InputErrorComponent,
    InputHelpComponent,
    KeyMetricsComponent,
    LiveIconComponent,
    OnlineStatusComponent,
    PasscodeInputComponent,
    Panel2Component,
    Panel3Component,
    PopoverListComponent,
    SignoutButtonComponent,
    SpinnerButtonComponent,
    ToolbarComponent,
    ToolbarPopoverComponent,
  ],
  exports: [
    AutosavedStatusComponent,
    CalendarIconComponent,
    ChangeChipComponent,
    ClockIconComponent,
    ColorPickerComponent,
    ComparisonComponent,
    InputErrorComponent,
    InputHelpComponent,
    KeyMetricsComponent,
    LiveIconComponent,
    OnlineStatusComponent,
    PasscodeInputComponent,
    Panel2Component,
    Panel3Component,
    PopoverListComponent,
    SignoutButtonComponent,
    SpinnerButtonComponent,
    ToolbarComponent,
    ToolbarPopoverComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class IonComponentsModule { }
