import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, merge, Subject, takeUntil } from 'rxjs';

import { MenuController } from '@ionic/angular';

import { A2hsService } from '@services/a2hs.service';
import { ActiveService } from '@services/active.service';
import { AuthService } from '@services/auth.service';
import { ColorsService } from '@services/colors.service';
import { DarkService } from '@services/dark.service';
import { DtService } from '@services/dt.service';
import { EnvService } from '@services/env.service';
import { LsService } from '@services/ls.service';
import { SignoutService } from '@services/signout.service';
import { UpdateService } from '@pages/update/update.service';
import { UtilitiesService } from '@services/utilities.service';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public version = '';

  private destroy$ = new Subject<void>();

  constructor(
    public a2hs: A2hsService,
    public active: ActiveService,
    public appState: AppState,
    public dt: DtService,
    public env: EnvService,
    public update: UpdateService,
    public userState: UserState,
    public util: UtilitiesService,
    private auth: AuthService,
    private colors: ColorsService,
    private dark: DarkService,
    private ls: LsService,
    private menu: MenuController,
    private renderer: Renderer2,
    private router: Router,
    private signout: SignoutService,
  ) {
    this.appState.hideSplash$
      .pipe(takeUntil(merge(this.destroy$, this.signout.signout$)))
      .subscribe(() => {
        const splash = this.renderer.selectRootElement('#splash');
        if (splash?.nativeElement) {
          const parent = splash.nativeElement.parentElement;
          this.renderer.removeChild(parent, splash);
        }
      });
  }

  async ngOnInit(): Promise<void> {
    this.appState.urlParams = window.location.search;
    void this.menu.enable(false);
    await this.checkBrowser();
    this.update.initUpdates();
    this.version = this.env.version;
    this.a2hs.listenForInstallPrompt();
    this.dark.checkColorMode();
    await this.menu.swipeGesture(false);  // Disable menu swipe when menu is closed

    // Update theme color based on Preferences
    const metaThemeColor = this.renderer.selectRootElement('meta[name="theme-color"]');
    this.renderer.setAttribute(metaThemeColor, 'content', this.colors.colors['header-dark']);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(merge(this.appState.hideSplash$, this.destroy$, this.signout.signout$)),
      )
      .subscribe(() => this.appState.hideSplash$.next());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async checkBrowser(): Promise<void> {
    // if (/MSIE |Trident\/|UC Browser|Chrome\//.test(window.navigator.userAgent)) {
    if (/MSIE |Trident\/|UC Browser/.test(window.navigator.userAgent)) {
      await this.router.navigate(['unsupported'], { state: { internalRequest: true } });
    }
  }

  public async updateMenuState(type: string): Promise<void> {
    this.appState.menuOpen = type === 'ionDidOpen';
    await this.menu.swipeGesture(type === 'ionDidOpen');  // Enable menu swipe only when menu is open
  }

  public async routerLink(route: string): Promise<void> {
    if (route.includes('/app/')) {
      this.ls.set('lastTab', route.substring(5));
    }

    // Prevent secondary pages from being added to the page history when side menu is always shown
    await this.router.navigateByUrl(route, {
      replaceUrl:
        !this.util.device.smallerThanLaptop &&
        !this.router.url.includes('app/insights') &&
        !this.router.url.includes('app/dashboard') &&
        !this.router.url.includes('app/trends'),
    });
  }

  public async signOut(): Promise<void> {
    await this.menu.toggle();
    await this.auth.signOut();
  }
}
