import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { SweetAlertIcon, SweetAlertResult } from 'sweetalert2/dist/sweetalert2.js';

import { UtilitiesService } from '@services/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private displayTimeout: NodeJS.Timeout | undefined;
  private cancelTimeout: NodeJS.Timeout | undefined;

  private messageDisplayed = false;
  private blockLoadingMessages = false;

  constructor(
    private router: Router,
    private util: UtilitiesService,
  ) { }

  public loadingMessage(message?: string, minSeconds = 1, maxSeconds = 10): void {
    if (this.blockLoadingMessages) return;
    if (message) {
      this.display(message, minSeconds, maxSeconds);
    } else {
      this.cancel();
    }
  }

  private display(message: string, minSeconds: number, maxSeconds: number): void {
    if (this.messageDisplayed) {
      Swal.update({ html: message.replace(/\n/g, '<br>') });
      Swal.showLoading();
    } else {
      void Swal.fire({
        willOpen: () => Swal.showLoading(),
        html: message.replace(/\n/g, '<br>'),
        backdrop: true,
        heightAuto: false,
        showClass: { popup: 'animated zoomIn faster' },
        hideClass: { popup: 'animated zoomOut faster' },
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: { loader: 'custom-loader' },
      });
      this.messageDisplayed = true;
    }

    if (this.displayTimeout) clearTimeout(this.displayTimeout);
    if (this.cancelTimeout) clearTimeout(this.cancelTimeout);

    this.displayTimeout = setTimeout(() => {
      this.displayTimeout = undefined;
    }, minSeconds * 1000);

    this.cancelTimeout = setTimeout(() => {
      this.cancel();
      this.cancelTimeout = undefined;
    }, maxSeconds * 1000);
  }

  public async loadingMessageAwait(message?: string, minSeconds = 1, maxSeconds = 10): Promise<void> {
    if (this.blockLoadingMessages) return;
    if (message) {
      await this.displayAwait(message, minSeconds, maxSeconds);
    } else {
      this.cancel();
    }
  }

  private async displayAwait(message: string, minSeconds: number, maxSeconds: number): Promise<void> {
    if (this.messageDisplayed) {
      Swal.update({ html: message.replace(/\n/g, '<br>') });
      Swal.showLoading();
    } else {
      await this.fireSwal(message);
    }

    if (this.displayTimeout) clearTimeout(this.displayTimeout);
    if (this.cancelTimeout) clearTimeout(this.cancelTimeout);

    this.displayTimeout = setTimeout(() => {
      this.displayTimeout = undefined;
    }, minSeconds * 1000);

    this.cancelTimeout = setTimeout(() => {
      this.cancel();
      this.cancelTimeout = undefined;
    }, maxSeconds * 1000);
  }

  private fireSwal(message: string): Promise<void> {
    return new Promise(resolve => {
      this.messageDisplayed = true;
      void Swal.fire({
        willOpen: () => Swal.showLoading(),
        html: message.replace(/\n/g, '<br>'),
        backdrop: true,
        heightAuto: false,
        showClass: { popup: 'animated zoomIn faster' },
        hideClass: { popup: 'animated zoomOut faster' },
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: { loader: 'custom-loader' },
        // Ignore this warning message
        didOpen: async () => {
          Swal.showLoading();
          await this.util.sleep(0.1);
          resolve();
        },
      });
    });
  }

  private cancel(): void {
    if (this.messageDisplayed) {
      const intervalId = setInterval(() => {
        if (!this.displayTimeout) {
          Swal.close();
          this.messageDisplayed = false;
          if (this.cancelTimeout) {
            clearTimeout(this.cancelTimeout);
            this.cancelTimeout = undefined;
          }
          clearInterval(intervalId);
        }
      }, 50);
    }
  }

  public simpleMessage(title: string, text: string, icon: SweetAlertIcon): void {
    void Swal.fire({
      icon: icon,
      titleText: title,
      html: text.replace(/\n/g, '<br>'),
      showConfirmButton: false,
      allowOutsideClick: false,
      backdrop: true,
      heightAuto: false,
      showClass: { popup: 'animated zoomIn faster' },
      hideClass: { popup: 'animated zoomOut faster' },
      timer: 30000,
    });
  }

  public simpleMessageClose(): void {
    Swal.close();
  }

  public async message(title: string, text: string, icon: SweetAlertIcon, buttonText: string, leftJustified = false):
    Promise<void> {

    await this.blockAlerts();

    await Swal.fire({
      icon: icon,
      titleText: title,
      html: text.replace(/\n/g, '<br>'),
      confirmButtonText: buttonText,
      confirmButtonColor: `var(--ion-color-${['error', 'warning'].includes(icon) ? 'danger' : 'accent'}`,
      allowOutsideClick: false,
      backdrop: true,
      heightAuto: false,
      showClass: { popup: 'animated zoomIn faster' },
      hideClass: { popup: 'animated zoomOut faster' },
      customClass: leftJustified ? { htmlContainer: 'custom-left-justify' } : {},
    });

    this.unblockAlerts();
  }

  public async messageWithImage(title: string, text: string, imageURL: string, imageWidth: number,
    imageHeight: number, confirmButtonText = 'OK'): Promise<boolean> {

    await this.blockAlerts();

    const result = await Swal.fire({
      title: title,
      html: text.replace(/\n/g, '<br>'),
      confirmButtonText: confirmButtonText,
      confirmButtonColor: `var(--ion-color-accent)`,
      imageUrl: imageURL,
      imageWidth: imageWidth,
      imageHeight: imageHeight,
      imageAlt: title,
      allowOutsideClick: false,
      backdrop: true,
      heightAuto: false,
      showLoaderOnConfirm: false,
      showClass: { popup: 'animated zoomIn faster' },
      hideClass: { popup: 'animated zoomOut faster' },
    });

    this.unblockAlerts();
    return !!result?.value;
  }

  public async confirm(title: string, text: string, icon: SweetAlertIcon, confirmButtonText: string,
    cancelButtonText?: string): Promise<boolean> {

    await this.blockAlerts();

    const result = await Swal.fire({
      icon,
      titleText: title,
      html: text.replace(/\n/g, '<br>'),
      showCancelButton: !!cancelButtonText,
      confirmButtonText,
      confirmButtonColor: `var(--ion-color-${['error', 'warning'].includes(icon) ? 'danger' : 'accent'}`,
      cancelButtonText,
      allowOutsideClick: false,
      backdrop: true,
      heightAuto: false,
      showClass: { popup: 'animated zoomIn faster' },
      hideClass: { popup: 'animated zoomOut faster' },
    });

    this.unblockAlerts();
    return !!result?.value;
  }

  public async stayOrRoute(title: string, text: string, icon: SweetAlertIcon, cancelButtonText: string,
    confirmButtonText: string): Promise<SweetAlertResult> {

    await this.blockAlerts();

    const result = Swal.fire({
      icon: icon,
      titleText: title,
      html: text.replace(/\n/g, '<br>'),
      showCancelButton: true,
      cancelButtonText: cancelButtonText,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: `var(--ion-color-${['error', 'warning'].includes(icon) ? 'danger' : 'accent'}`,
      allowOutsideClick: false,
      backdrop: true,
      heightAuto: false,
      showClass: { popup: 'animated zoomIn faster' },
      hideClass: { popup: 'animated zoomOut faster' },
    });

    this.unblockAlerts();
    return result;
  }

  public async TryAgainOrSignin(title: string, text: string, icon: SweetAlertIcon, cancelButtonText: string,
    confirmButtonText: string): Promise<void> {

    await this.blockAlerts();

    const result = await Swal.fire({
      icon: icon,
      titleText: title,
      html: text.replace(/\n/g, '<br>'),
      showCancelButton: true,
      cancelButtonText: cancelButtonText,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: `var(--ion-color-${['error', 'warning'].includes(icon) ? 'danger' : 'accent'}`,
      allowOutsideClick: false,
      backdrop: true,
      heightAuto: false,
      showClass: { popup: 'animated zoomIn faster' },
      hideClass: { popup: 'animated zoomOut faster' },
    });

    await this.blockAlerts();
    if (result.value) await this.router.navigate(['signin']);
  }

  private async blockAlerts(): Promise<void> {
    // Clear out previous alerts and block new ones
    this.blockLoadingMessages = true;
    this.messageDisplayed = false;
    if (this.displayTimeout) clearTimeout(this.displayTimeout);
    this.cancel();
    await this.util.sleep(0.5);
  }

  private unblockAlerts(): void {
    this.blockLoadingMessages = false;
  }

  public toast(text: string, icon: SweetAlertIcon, closeButtonText = 'Close', seconds = 5): void {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: true,
      timer: seconds * 1000,
      timerProgressBar: true,
    });

    void toast.fire({
      icon: icon,
      title: '',
      html: text.replace(/\n/g, '<br>'),
      showConfirmButton: !!closeButtonText.length,
      confirmButtonText: closeButtonText,
      confirmButtonColor: `var(--ion-color-${['error', 'warning'].includes(icon) ? 'danger' : 'accent'}`,
    });
  }
}
