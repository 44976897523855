/* eslint-disable @typescript-eslint/no-empty-function */
import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

import '@services/firebase.service'; // Import for Firebase initialization
import '@services/dt.service'; // Import for date initialization

if (environment.production) {
  enableProdMode();

  if (window && environment.production && environment.firebase.projectId === 'manage-my-business') {
    window.console.log = () => { };
    window.console.time = () => { };
    window.console.timeEnd = () => { };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(error => console.error(error));
