import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, debounceTime, fromEvent, merge, Subject, takeUntil } from 'rxjs';

import { SignoutService } from '@services/signout.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveService implements OnDestroy {
  public isActive$ = new BehaviorSubject<boolean>(true);
  public isConnected = true;
  private isVisible = true;
  public asleepMinutes = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private signout: SignoutService,
  ) {
    this.resumeIfPaused();

    // Set default online state and then listen for changes
    if (navigator.onLine) {
      this.changesIfOnline();
    } else {
      this.changesIfOffline();
    }

    fromEvent(window, 'online')
      .pipe(takeUntil(merge(this.destroy$, this.signout.signout$)))
      .subscribe(() => this.changesIfOnline());

    fromEvent(window, 'offline')
      .pipe(takeUntil(merge(this.destroy$, this.signout.signout$)))
      .subscribe(() => this.changesIfOffline());

    fromEvent(document, 'visibilitychange')
      .pipe(
        takeUntil(merge(this.destroy$, this.signout.signout$)),
        debounceTime(50),
      )
      .subscribe(() => {
        const isVisible = document.visibilityState === 'visible';
        if (this.isVisible !== isVisible) this.setIsVisible(isVisible);
      });

    merge(fromEvent(window, 'focus'), fromEvent(window, 'resume'))
      .pipe(
        takeUntil(merge(this.destroy$, this.signout.signout$)),
        debounceTime(50),
      )
      .subscribe(() => {
        if (!this.isVisible) this.setIsVisible(true);
      });

    merge(fromEvent(window, 'blur'), fromEvent(window, 'freeze'))
      .pipe(
        takeUntil(merge(this.destroy$, this.signout.signout$)),
        debounceTime(50),
      )
      .subscribe(() => {
        if (this.isVisible) this.setIsVisible(false);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private resumeIfPaused(): void {
    let timestamp = new Date().getTime();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    setInterval(checkResume, 2000);

    function checkResume() {
      const current = new Date().getTime();
      _this.asleepMinutes = Math.round((current - timestamp) / 1000 / 60);

      if (current - timestamp > 60 * 60 * 1000) {  // Reload if asleep more than one hour
        window.location.reload();
      } else if (current - timestamp > 5 * 1000) {  // Trigger resume event if alive for more than 3 seconds
        const resumeEvent = new Event('resume');
        window.dispatchEvent(resumeEvent);
      }
      timestamp = current;
    }
  }

  private changesIfOnline(): void {
    if (!this.isConnected) {
      this.setIsConnected(true);
    }
  }

  private changesIfOffline(): void {
    if (this.isConnected) {
      this.setIsConnected(false);
    }
  }

  private setIsConnected(connected: boolean): void {
    this.isConnected = connected;
    this.updateIsActiveSubject();
  }

  private setIsVisible(visible: boolean): void {
    this.isVisible = visible;
    this.updateIsActiveSubject();
  }

  private updateIsActiveSubject(): void {
    // Only update subject if value changes
    if (this.isActive$.value !== (this.isConnected && this.isVisible)) {
      this.isActive$.next(this.isConnected && this.isVisible);
    }
  }
}
