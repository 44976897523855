import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ErrorService } from '@services/error.service';
import { LsService } from '@services/ls.service';

@Injectable({
  providedIn: 'root',
})
export class DarkService {
  public darkMode$ = new Subject<boolean>();
  public colorScheme$ = new Subject<number>();
  public showDark = false;
  public colorMode = 1; // 0 = dark, 1 = auto, 2 = light
  public colorScheme = 0; // 0 = red, 1 = black

  constructor(
    private error: ErrorService,
    private ls: LsService,
  ) {
    this.checkColorMode();
  }

  public checkColorMode(): void {
    this.colorMode = Number(this.ls.get('colorMode', '1'));
    this.colorScheme = Number(this.ls.get('colorScheme', '0'));
    this.setColorScheme(this.colorScheme);

    if (this.colorMode === 1) {
      const osDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
      this.setDarkTheme(osDarkMode.matches);

      try {
        osDarkMode.addEventListener('change', e => this.setDarkTheme(e.matches));
      } catch (error) {
        console.error(`APP dark.checkColorMode: Listener not supported for ${window.navigator.userAgent}, ` +
          `${this.error.toStr(error)}`);
      }
    } else {
      this.setDarkTheme(this.colorMode === 0);
    }
  }

  private setDarkTheme(showDark: boolean): void {
    if (this.showDark !== showDark) {
      this.showDark = showDark;

      // Update Ionic theme
      document.body.classList.toggle('dark', showDark);

      // Update only after the initial mode is set
      if (this.showDark !== undefined) {
        this.darkMode$.next(showDark);
      }
    }
  }

  public setColorMode(value: number): void {
    if (this.colorMode !== value) {
      this.colorMode = value;
      this.ls.set('colorMode', String(this.colorMode));
      this.checkColorMode();
    }
  }

  public setColorScheme(value: number): void {
    if (this.colorScheme !== value) {
      this.colorScheme = value;
      this.ls.set('colorScheme', String(this.colorScheme));
      this.colorScheme$.next(this.colorScheme);
    }
  }
}
