// Angular imports
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

// Ionic imports
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Environment imports
import { environment } from '@environments/environment';

// App component imports
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';

// Sentry component imports
import { captureConsoleIntegration, init } from '@sentry/browser';

// Pipe imports
import { PipesModule } from '@pipes/pipes.module';

// Service imports
import { EnvService } from '@services/env.service';
import { ErrorService } from '@services/error.service';

// Component imports
import { IonComponentsModule } from '@ion-components/ion-components.module';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    private env: EnvService,
    private error: ErrorService,
  ) {
    if (env.useSentry) {
      init({
        dsn: 'https://baf32edb1fae449b941f43188d6ac0f8@o413292.ingest.sentry.io/5298407',
        environment: this.env.name,
        debug: false,
        release: this.env.version,
        ignoreErrors: this.error.toIgnore(),
        integrations: [
          captureConsoleIntegration({ levels: ['error'] })],
      });
    }
  }

  public async handleError(error: Error): Promise<void> {
    if (this.env.test) {
      this.error.handlerComplete$.next();
      console.error(error);
    } else {
      await this.error.handleError(error);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    HttpClientModule,
    IonicModule.forRoot(),
    PipesModule,
    IonComponentsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
