import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { NavigateService } from '@services/navigate.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalGuard {

  constructor(
    private navigate: NavigateService,
    private router: Router,
  ) { }

  public async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isHomePageRequest = state.url === '/';
    const isInternalNavigation = !!this.router.lastSuccessfulNavigation;

    if (isHomePageRequest || isInternalNavigation) return true;

    await this.navigate.home();
    return false;
  }
}
