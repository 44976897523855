export const environment = {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-require-imports
  version: require('../../package.json').version,
  production: true,
  firebase: {
    apiKey: 'AIzaSyAvn6AlSLHcmJ2Cy-y67dbMJBGy-HGN3ZM',
    authDomain: getAuthDomain(),
    databaseURL: getDatabaseURL(),
    projectId: 'manage-my-business-dev',
    storageBucket: 'manage-my-business-dev.appspot.com',
    messagingSenderId: '20282036500',
    appId: '1:20282036500:web:4f4d7953e898e17a9cf201',
    measurementId: 'G-L08R4HE33J',
  },
};

function getAuthDomain() {
  return window.location.origin.includes('managemybusiness') ?
    'secure-dev.managemybusiness.app' : 'secure-dev.getasprk.com';
}

function getDatabaseURL() {
  return window.location.origin.includes('managemybusiness') ?
    'https://secure-dev.managemybusiness.app' : 'https://secure-dev.getasprk.com';
}
